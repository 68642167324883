import React from "react";
import Container from "../Container";
import ListItem from "../ListItem";
import testimonials from "../../data/testimonials";
import TestimonialCard from "../TestimonialCard";
import FadeUp from "../animations/FadeUp";

export default function CustomWebsiteBenefit() {
    const featuredTestimonial = testimonials.filter(
        (t) => t.client === "Holden Karau"
    )[0];

    return (
        <section className="bg-white-200 py-20">
            <FadeUp>
                <Container>
                    <h2 className="text-4xl font-semibold">
                        I build{" "}
                        <span className="text-primary-500">truly custom</span>{" "}
                        websites
                    </h2>

                    <div className="flex gap-x-24 gap-y-12 flex-wrap flex-col md:flex-row mt-20">
                        <ul className="flex flex-col gap-y-4 grow basis-0">
                            <ListItem>
                                Most developers use pre-made templates with
                                little customization. I don't. It's terrible for
                                your brand.
                            </ListItem>
                            <ListItem>
                                I build custom websites that match your brand's
                                vibe. Your audience will instantly know it's
                                you.
                            </ListItem>
                            <ListItem>
                                No plugins to update. No third-party apps to
                                manage. No wrestling with your editor to get
                                some custom feature working. Just one website
                                that just works.
                            </ListItem>
                        </ul>

                        <div
                            className={"grow basis-0 flex flex-col gap-y-6"}
                            breakpoint={"md"}
                        >
                            <TestimonialCard
                                {...featuredTestimonial}
                                className={"shadow-2xl"}
                            />
                        </div>
                    </div>
                </Container>
            </FadeUp>
        </section>
    );
}
