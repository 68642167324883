import React from "react";
import Container from "../Container";
import PrimaryButton from "../buttons/PrimaryButton";
import "swiper/css";
import openCalendlyPopup from "../../data/calendly";
import PortfolioCarousel from "./PortfolioCarousel";
import TestimonialSummary from "../TestimonialSummary";

export default function Hero() {
    return (
        <section className="bg-gradient-to-b from-black-500 to-black-300 text-white-200 grid grid-cols-1 lg:grid-cols-2">
            <Container>
                <div className="flex flex-col items-start justify-around h-full py-20 lg:py-0">
                    <div>
                        <p className="uppercase mb-2 font-bold text-primary-50">
                            Custom designs, custom code
                        </p>
                        <h1 className="text-2xl sm:text-4xl !leading-snug mb-6 max-w-screen-lg">
                            I help{" "}
                            <span className="text-primary-500">
                                startups and small businesses
                            </span>{" "}
                            launch their first website
                        </h1>
                        <h2 className="mb-6 text-xl">
                            No Wordpress or page builders. No overused
                            templates. I build fully custom, hand-coded websites
                            that stand out from the masses.
                        </h2>
                        <PrimaryButton
                            onClick={openCalendlyPopup}
                            className={
                                "flex items-center justify-center gap-x-2 fill-white-100"
                            }
                        >
                            <p>Book a free meeting</p>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 -960 960 960"
                                >
                                    <path d="m256-240-56-56 384-384H240v-80h480v480h-80v-344z" />
                                </svg>
                            </div>
                        </PrimaryButton>
                    </div>

                    <div>
                        <div className="mt-10">
                            <TestimonialSummary />
                        </div>

                        <div className="my-6 rounded-full bg-black-400 px-4 py-1">
                            Over{" "}
                            <span className="text-primary-100 font-bold">
                                50 websites
                            </span>{" "}
                            for clients across the world, and yours could be
                            next.
                        </div>
                    </div>
                </div>
            </Container>
            <div className="h-full">
                <PortfolioCarousel />
            </div>
        </section>
    );
}
