import React from "react";

export default function BenefitTextCard({ title, icon, children }) {
    return (
        <div className="basis-0 grow min-w-72 p-5 rounded-2xl bg-white-200 drop-shadow-xl relative overflow-hidden isolate group">
            <h3 className="text-2xl mb-6">{title}</h3>

            {children}

            <div className="absolute top-0 right-0 p-3 m-2 group-hover:bg-white-100 rounded-full duration-500 delay-200">
                {icon}
            </div>

            <div className="absolute -top-16 -right-16 bg-white-600 rounded-b-full w-32 h-32 -z-10 group-hover:top-0 group-hover:right-0 group-hover:w-[200%] group-hover:h-[200%] group-hover:rounded-b-none duration-1000"></div>
            {/* <div className="absolute -bottom-16 -left-16 bg-white-600 rounded-t-full w-32 h-32 -z-10 group-hover:bottom-0 group-hover:left-0 group-hover:w-full group-hover:h-full duration-500"></div> */}
        </div>
    );
}
