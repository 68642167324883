const testimonials = [
    {
        img: "./images/testimonials/jennycho.webp",
        text: "Well worth the money. Hassan is phenomenal at programming responsive websites, and designing these websites from scratch. I vouch. With love, a delighted customer who got his money's worth.",
        client: "Jenny Cho",
        role: "coloredmanga, Founder",
    },
    {
        img: "./images/testimonials/holdenkarau.jpg",
        text: "Great job, very responsive to feedback, we iterated on the design and came up with something that really fit the look and feel we were going for. Amazing job! I love the work so much!",
        client: "Holden Karau",
        role: "PCF Labs, Founder",
    },
    {
        img: "./images/testimonials/silvio.webp",
        text: "I had a great experience with Hassan! I was looking for my first landing page that had to be interactive and he did beautifully and professionally. Excellent communication as well.",
        client: "Silvio",
        role: "HouSoon",
    },
    {
        img: "./images/testimonials/alankargupta.webp",
        text: "Great service by the seller. Really professional and very effective communication. His front end skills are very nice.",
        client: "Alankar Gupta",
        role: "NLP Sentiment Analysis",
    },
    {
        img: "./images/testimonials/anastasiaederer.png",
        text: "I had to get a website showcasing my social media content and management for an important deadline up and running in a week. Hassan was able to efficiently communicate and execute my vision and cater to my needs in no time with an unimaginable ease. He is super efficient, creative, and has an admirable work ethic. His work is outstanding and highly recommend him! Great experience and great relationship was developed! Thank you Hassan!",
        client: "Anastasia Ederer",
        role: "Founder, EZ Marketing",
    },
    {
        img: "./images/testimonials/ahmedsaleem.webp",
        text: "It was an amazing experience working with him. He is really expert in what he is doing. Highly recommended",
        client: "Ahmed Saleem",
        role: "Google APIs integration",
    },
    {
        img: "./images/testimonials/asido.webp",
        text: "Great to work with! Very knowledgeable and friendly. Looking forward to continued sessions!",
        client: "Asido",
        role: "Tutoring",
    },
    {
        img: null,
        text: "Working with Hassan on Roami has been fantastic. Their expertise and dedication brought our vision to life, creating an image-sharing platform that exceeds expectations.",
        client: "JN. Burton",
        role: "Roami, CEO",
    },
    {
        img: null,
        text: "Excellent guy. Works really quick and efficiently and takes pride in his work. Has made my ideas become reality.",
        client: "Josiah Trotter",
        role: "LWAN, CEO",
    },
];

export default testimonials;