import React, { useEffect, useLayoutEffect, useState } from "react";

export default function Masonry({ items, chunkSize, gap, renderElem }) {
    // TODO: This is NOT responsive. Since you break them into chunks, they won't render correctly when a subgrid is pushed to the next row.

    const cols = {
        1: "grid-cols-1",
        2: "grid-cols-2",
        3: "grid-cols-2 lg:grid-cols-3",
        4: "grid-cols-4",
    };
    const [chunks, setChunks] = useState(collectChunks(items));

    function renderChunk(i, chunk, renderElem) {
        return (
            <div className="flex flex-col gap-6" key={i}>
                {chunk.map((e, i) => renderElem(e, i))}
            </div>
        );
    }

    function collectChunks(array) {
        const c = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            c.push(chunk);
        }
        console.log("Chunks collected... ", c.length);
        return c;
    }

    useEffect(() => setChunks(collectChunks(items)), [items]);

    return (
        <div className={`grid gap-6 ${cols[chunks.length]}`}>
            {chunks.map((chunk, i) => renderChunk(i, chunk, renderElem))}
        </div>
    );
}
