import React from "react";
import Container from "../Container";
import FadeUp from "../animations/FadeUp";
import testimonials from "../../data/testimonials";
import BenefitImageCard from "../cards/BenefitImageCard";

export default function HereForYouBenefit() {
    const featuredTestimonial = testimonials.filter(
        (t) => t.client === "Alankar Gupta"
    )[0];

    return (
        <section className="py-20 bg-black-400 text-white-200">
            <FadeUp>
                <Container>
                    <h2 className="text-4xl font-semibold">
                        I'm here for you, every step of the way.
                    </h2>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 my-10">
                        <BenefitImageCard
                            title={"Regular Updates"}
                            imgSrc={"./images/holden-contact.png"}
                            imgAlt={"email communication"}
                            direction="row"
                            text={`
                                I'll send you regular updates, a couple every week.
                                This is your website, and your input is the most valuable.
                                Unlike many other agencies, I always involve you in the
                                development process. I regularly send several updates about
                                your project each week.
                                `}
                        />
                        <BenefitImageCard
                            title={"Educating"}
                            imgSrc={"./images/substack-posts.png"}
                            imgAlt={"substack posts list"}
                            direction="row"
                            text={`
                                    I teach you about my design/development
                                    process and the decisions I make for your
                                    website, so you see the logic behind it. I
                                    also write on my blog for those of you who
                                    want to learn even more.
                                    `}
                        />
                        <BenefitImageCard
                            title={"Real-time progress"}
                            imgSrc={"./images/real-time-updates.png"}
                            imgAlt={"code and website side by side"}
                            direction="row"
                            className={"lg:col-span-2"}
                            text={`
                                See your website come together in real time. 
                                See my progress in real time as I develop
                                your site, and point out any changes you'd
                                like me to make.
                                `}
                        >
                            <p className="my-6">
                                Don't have a server ready? No problem. I've got
                                a bunch of servers to temporarily host your
                                website while we develop it and prepare
                                everything.
                            </p>
                            <p className="my-6">
                                You'll never be left in the dark thinking,{" "}
                                <span className="italic">
                                    "what is my developer even doing right now?"
                                </span>
                                .
                            </p>
                        </BenefitImageCard>
                    </div>

                    {/* <div className="mt-20 max-w-screen-md flex flex-col gap-y-12">
                        <div className="my-4">
                            <div className="border-b border-b-primary-500 pb-2 mb-2 flex gap-x-2 stroke-white-600">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
                                    <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
                                </svg>
                                <h4>
                                    I'll send you{" "}
                                    <span className="text-primary-500">
                                        regular updates
                                    </span>
                                    , a couple every week.
                                </h4>
                            </div>
                            <p className="text-white-600">
                                This is your website, and your input is the most
                                valuable. Unlike many other agencies, I always
                                involve you in the development process. I
                                regularly send several updates about your
                                project each week.
                            </p>
                        </div>

                        <div className="my-4">
                            <div className="border-b border-b-primary-500 pb-2 mb-2 flex gap-x-2 stroke-white-600">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <circle cx="12" cy="12" r="10" />
                                    <polyline points="12 6 12 12 16 14" />
                                </svg>
                                <h4>
                                    See your website come together in{" "}
                                    <span className="text-primary-500">
                                        real time
                                    </span>
                                </h4>
                            </div>
                            <p className="text-white-600">
                                See my progress in real time as I develop your
                                site, and point out any changes you'd like me to
                                make.
                            </p>
                        </div>

                        <div className="my-4">
                            <div className="border-b border-b-primary-500 pb-2 mb-2 flex gap-x-2 stroke-white-600">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                                    <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
                                    <path d="M6 8h2" />
                                    <path d="M6 12h2" />
                                    <path d="M16 8h2" />
                                    <path d="M16 12h2" />
                                </svg>
                                <h4>
                                    <span className="text-primary-500">
                                        Educating and empowering{" "}
                                    </span>
                                    you in the web space.
                                </h4>
                            </div>
                            <p className="text-white-600">
                                I teach you about my design/development process
                                and the decisions I make for your website, so
                                you see the logic behind it. I also write on my
                                blog for those of you who want to learn even
                                more.
                            </p>
                        </div>
                    </div> */}
                </Container>
            </FadeUp>
        </section>
    );
}
