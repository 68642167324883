import React from "react";
import Page from "./Page";
import MyStory from "../sections/MyStory";
import HowItStarted from "../sections/HowItStarted";
import FreelancerJourney from "../sections/FreelancerJourney";

export default function About() {
    return (
        <Page title="About Me - Hassandev">
            <MyStory />
            <HowItStarted />
            <FreelancerJourney />
        </Page>
    );
}
