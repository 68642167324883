import React from "react";
import Button from "./Button";

export default function PrimaryButton({ onClick, className, children }) {
    return (
        <Button
            onClick={onClick}
            className={
                `bg-primary-500 hover:bg-primary-700 active:bg-primary-900 ${className}`
            }
        >
            {children}
        </Button>
    );
}
