import React from "react";

export default function Quote({ children, breakpoint, className }) {
    const breakpoints = {
        "md": "border-t-4 pt-2 md:border-t-0 md:pt-0 md:border-l-4 md:pl-8"
    };

    return (
        <div className={`${breakpoints[breakpoint]} border-primary-500 ${className}`}>
            {children}
        </div>
    );
}
