import React from "react";

export default function StepperItem({ num, children, join }) {
    const joins = {
        'after': 'after:absolute after:bg-primary-500 after:top-0 after:left-[15px] after:h-full after:w-1',
        'before': 'before:hidden'
    }
    return (
        <div className={`flex relative ${joins[join] || joins["after"]}`}>
            <div className="flex items-center justify-center rounded-full min-w-8 h-8 bg-primary-500 mb-4 text-white-100 z-10">
                {num}
            </div>
            <div className="ml-4 mb-10">
                {children}
            </div>
        </div>
    );
}
