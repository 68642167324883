import { motion } from "motion/react";
import React from "react";

export default function FadeUp({ duration = 0.5, children, className }) {
    return (
        <motion.div
            initial={{
                translateY: "10%",
                opacity: 0,
                transition: { duration: duration },
            }}
            whileInView={{
                translateY: "0%",
                opacity: 1,
                transition: { duration: duration },
            }}
            viewport={{
                once: true
            }}
            exit={{
                translateY: "10%",
                opacity: 0,
                transition: { duration: duration },
            }}
            className={className}
        >
            {children}
        </motion.div>
    );
}
