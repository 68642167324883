import React from "react";

export default function TestimonialSummary() {
    const clientImgs = [
        { path: "./images/testimonials/anastasiaederer.png", shift: "-ms-0" },
        { path: "./images/testimonials/silvio.webp", shift: "-ms-2" },
        { path: "./images/testimonials/asido.webp", shift: "-ms-2" },
        { path: "./images/testimonials/alankargupta.webp", shift: "-ms-2" },
    ];
    return (
        <div className="flex items-center gap-2 flex-wrap">
            <div className="flex">
                {clientImgs.map((img, i) => (
                    <img
                        src={img.path}
                        alt="client"
                        className={`w-14 h-14 rounded-full object-cover border-2 ${
                            i > 0 ? "relative " + img.shift : ""
                        }`}
                    />
                ))}
            </div>
            <p>17+ clients served</p>
        </div>
    );
}
