import React from "react";

export default function ListItem({ children }) {
    return (
        <li className="flex gap-x-2 items-baseline">
            <div className="min-w-4 min-h-4 translate-y-1 rounded-full bg-primary-500"></div>
            <div>{children}</div>
        </li>
    );
}
