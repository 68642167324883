import React from "react";
import Container from "../Container";
import Quote from "../Quote";
import Paragraph from "../misc/Paragraph";

export default function FreelancerJourney() {
    return (
        <section className="bg-white-100 pt-20">
            <Container>
                <h2 className="text-2xl font-bold text-right mb-6">
                    My Journey as a <span className="text-primary-400">Freelancer</span>
                </h2>

                <div className="flex flex-col md:flex-row gap-12">
                    <div className="basis-0 grow">
                        <Quote breakpoint={"md"}>
                            <p className="leading-normal text-3xl italic">
                                I spent a couple of years on Fiverr, sharpening
                                my skills, delivering top-notch service, and
                                building strong client relationships.
                            </p>
                        </Quote>
                    </div>
                    <div className="basis-0 grow">
                        <Paragraph>
                            In 2019, I took my first steps into the freelancing
                            world by creating a profile on Fiverr. That's where
                            I started working with clients, setting up my gigs
                            and sending out proposals. Before long, I was
                            getting steady work from a variety of clients. I
                            always put my best foot forward, making sure every
                            client was happy with the results.
                        </Paragraph>
                        <Paragraph>
                            My old profile is still up, and you can check it out
                            here if you're curious. I spent a couple of years on
                            Fiverr, sharpening my skills, delivering top-notch
                            service, and building strong client relationships .
                            However, due to some changes in Fiverr's approach
                            towards freelancers, I decided it was time to move
                            on and start my own agency.
                        </Paragraph>
                        <Paragraph>
                            And that's how Hassandev was born, where I've been
                            focused ever since.
                        </Paragraph>
                    </div>
                </div>
            </Container>
        </section>
    );
}
