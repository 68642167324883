import React from "react";

export default function Button({ onClick, children, className }) {
    return (
        <button
            onClick={onClick}
            className={`px-6 py-3 rounded-md duration-300 ${className}`}
        >
            {children}
        </button>
    );
}
