import React from "react";
import Button from "./Button";

export default function PrimaryOutlineButton({ onClick, className, children }) {
    return (
        <Button
            onClick={onClick}
            className={`border-2 border-primary-500 bg-white-200 hover:bg-white-400 active:bg-primary-100 ${className}`}
        >
            {children}
        </Button>
    );
}
