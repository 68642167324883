import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import Modal from "../layouts/Modal";

export default function PortfolioCarousel() {
    const imgs = [
        {
            color: "bg-[#DEF7F5]",
            img: "./images/portfolio/wanderly.png",
            state: useState(false),
        },
        {
            color: "bg-[#FFF0EB]",
            img: "./images/portfolio/brics.png",
            state: useState(false),
        },
        {
            color: "bg-[#C5DBFD]",
            img: "./images/portfolio/roami.png",
            state: useState(false),
        },
        {
            color: "bg-[#FEE6DA]",
            img: "./images/portfolio/hamdard.png",
            state: useState(false),
        },
        {
            color: "bg-[#C2DFFF]",
            img: "./images/portfolio/velocia.png",
            state: useState(false),
        },
    ];

    return (
        <Swiper
            loop
            autoplay={{
                delay: 3000,
            }}
            modules={[Autoplay]}
            className="h-full"
        >
            {imgs.map((pi) => (
                <SwiperSlide className="!h-auto">
                    <div
                        className={`flex flex-col items-center justify-center h-full ${pi.color}`}
                    >
                        <img
                            src={pi.img}
                            onClick={() => pi.state[1](true)}
                            alt="featured project display"
                            className="object-cover max-h-screen"
                        />
                        <Modal openstate={pi.state} url={pi.img}>
                            <div className="max-w-screen-md opacity-0 group-[.active]:opacity-100 duration-700">
                                <img
                                    src={pi.img}
                                    alt="featured project display"
                                    className="object-cover"
                                />
                            </div>
                        </Modal>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
