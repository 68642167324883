import React from "react";
import Container from "../Container";
import PrimaryButton from "../buttons/PrimaryButton";
import RoundedIcon from "../icons/RoundedIcon";
import SocialIcons from "../SocialIcons";
import openCalendlyPopup from "../../data/calendly";
import { NavLink } from "react-router";

export default function Footer() {
    return (
        <footer className="bg-gradient-to-br from-black-400 to-black-600 text-white-100 pt-60">
            <Container>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-10 sm:gap-x-10">
                    <div className="max-w-72">
                        <h1 className="uppercase text-2xl font-bold tracking-widest mb-6">
                            Hassan Aziz
                        </h1>
                        <p className="mb-6">
                            Hop on a free call and let's bring your project to
                            reality.
                        </p>
                        <PrimaryButton onClick={openCalendlyPopup}>Get In Touch</PrimaryButton>
                    </div>
                    <div className="max-w-72">
                        <p className="font-bold">Useful Links</p>

                        <div className="flex flex-col gap-y-2 mt-6">
                            <NavLink className={"hover:text-primary-500 duration-500"} to={"/about"}>
                                About
                            </NavLink>
                            <NavLink className={"hover:text-primary-500 duration-500"} to={"/work"}>
                                Projects
                            </NavLink>
                        </div>
                    </div>
                    <div className="max-w-72">
                        <p className="font-bold">Socials and Contact</p>

                        <SocialIcons />

                        <a
                            href="mailto:hassanaziz0012@gmail.com"
                            className="mt-6 hover:border-b border-b-primary-500 pb-2 inline-block"
                        >
                            <div className="flex items-center gap-x-2">
                                <div className="fill-primary-500">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                    </svg>
                                </div>
                                hassanaziz0012@gmail.com
                            </div>
                        </a>
                    </div>
                </div>

                <div className="border-t border-t-black-300 mt-8 py-8 flex items-center gap-x-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <circle cx="12" cy="12" r="10" />
                        <path d="M14.83 14.83a4 4 0 1 1 0-5.66" />
                    </svg>

                    <p>
                        Copyright Hassandev 2024, All Rights Reserved
                    </p>
                </div>
            </Container>
        </footer>
    );
}
