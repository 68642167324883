import React from "react";
import Navbar from "../Navbar";
import ConversationCTA from "../sections/ConversationCTA";
import Footer from "../sections/Footer";

export default function Page({ title = "Hassandev", children }) {
    return (
        <div>
            <title>{title}</title>
            <Navbar />
            {children}
            <ConversationCTA />
            <Footer />
        </div>
    );
}
