import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import PrimaryButton from "../buttons/PrimaryButton";
import PrimaryOutlineButton from "../buttons/PrimaryOutlineButton";

export default function Modal({ openstate, url, children }) {
    const [open, setOpen] = openstate;

    const modalBackdrop = useRef();

    function close() {
        setOpen(false);
    }

    function closeByBackdrop(e) {
        console.log(e.target);
        if (e.target === modalBackdrop.current) {
            setOpen(false);
        }
    }

    function closeByKey(e) {
        console.log(e);
    }

    useEffect(() => {
        function handleEscapeKey(event) {
            if (event.code === "Escape") {
                setOpen(false);
            }
        }

        document.addEventListener("keydown", handleEscapeKey);
        return () => document.removeEventListener("keydown", handleEscapeKey);
    }, []);

    return (
        <div>
            {createPortal(
                <div
                    ref={modalBackdrop}
                    onKeyDown={closeByKey}
                    onClick={closeByBackdrop}
                    class={`${
                        open === true ? "active block" : "hidden"
                    } group fixed inset-0 h-full bg-gray-700 bg-opacity-80 flex items-center justify-center z-50`}
                >
                    <div className="flex flex-col items-end mx-10">
                        <div className="flex gap-x-2 mb-2">
                            {url ? (
                                <a href={url} target="_blank" rel="noreferrer">
                                    <PrimaryOutlineButton
                                        className={
                                            "stroke-black-600 active:stroke-white-100"
                                        }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path d="M15 3h6v6" />
                                            <path d="M10 14 21 3" />
                                            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                                        </svg>
                                    </PrimaryOutlineButton>
                                </a>
                            ) : (
                                ""
                            )}
                            <PrimaryButton onClick={close}>
                                <svg
                                    className="stroke-white-100"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path d="M18 6 6 18" />
                                    <path d="m6 6 12 12" />
                                </svg>
                            </PrimaryButton>
                        </div>
                        {children}
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
}
