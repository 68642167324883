import React from "react";
import Hero from "../sections/Hero";
import Testimonials from "../sections/Testimonials";
import CustomWebsiteBenefit from "../sections/CustomWebsiteBenefit";
import HereForYouBenefit from "../sections/HereForYouBenefit";
import ListenUpSection from "../sections/ListenUpSection";
import HowItWorks from "../sections/HowItWorks";
import Page from "./Page";
import WhyWebsite from "../sections/WhyWebsite";

export default function Home() {
    return (
        <Page title="Hassan Aziz - Hassandev">
            <Hero />
            <WhyWebsite />
            {/* <Projects /> */}
            <Testimonials />
            <CustomWebsiteBenefit />
            <HereForYouBenefit />
            {/* <ListenUpSection /> */}
            <HowItWorks />
        </Page>
    );
}
